import React from 'react';

import "./Footer.css";
import Wave from "../../img/wave.png";

import { useLocation } from 'react-router-dom';


//ICONS
import Insta from '@iconscout/react-unicons/icons/uil-instagram';
import Facebook from '@iconscout/react-unicons/icons/uil-facebook';
import Github from '@iconscout/react-unicons/icons/uil-github';

const Footer = () => {

  // Check if current path is the homepage
  const location = useLocation(); // Hook to get current route
  const isfounderPage = location.pathname === '/founder';

  return (
    <div className="footer">
      <img src={Wave} alt="Wave" style={{ width: "100%", zIndex: "-1" }} />
      <div className="f-content">
        <div className="f-icons">
          <Insta color="white" size="3rem" />
          <Facebook color="white" size="3rem" />
          <Github color="white" size="3rem" />
        </div>
        <span style={{ fontSize: "25px", }}>{isfounderPage ? `sumansaurabh00@gmail.com` : "www.sscvidya.in"}</span>

      </div>
    </div>
  )
}

export default Footer