import "./App.css";
import React from "react";
import Navbar from "./components/navbar/Navbar";
import Intro from "./components/intro/Intro";
import Services from "./components/services/Services";
import Experience from "./components/experience/Experience";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonial from "./components/testimonial/Testimonial";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import DummyPage from "./pages/QuizPage";
import BlogPage from "./pages/BlogsPage";
import LoginPage from "./pages/Authentication/Login";
import SignUpPage from "./pages/Authentication/Signup";
import LoginPromptModal from "./pages/Authentication/LoginPromptModal"; // Import your modal

import { themeContext } from "./Context";
import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./firebase/AuthListener"; // Import the AuthListener to start it
import HomePage from "./pages/homepage/HomePage";
import ScrollToTop from "./utilities/scrollToTop";
// import Summarizer from "./pages/SummarizePage";

import useLoginPromptAuth from "./utilities/LoginPromptAuth";

function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const { isModalOpen, closeModal } = useLoginPromptAuth();

  return (
    <Router>
      <ScrollToTop />
      <div
        className="App"
        style={{
          background: darkMode ? "black" : "",
          color: darkMode ? "white" : "",
        }}
      >
        <Navbar />
        <LoginPromptModal isOpen={isModalOpen} onClose={closeModal} />
        <Routes>
          {/* Main home page components */}
          <Route path="/" element={<HomePage />} />
          <Route
            path="/founder"
            element={
              <>
                <Intro />
                <Services />
                <Experience />
                <Portfolio />
                <Testimonial />
                <Contact />
              </>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* <Route path="/summary" element={<Summarizer />} /> */}

          {/* Route for DummyPage (Others page) */}
          <Route path="/quizCategory" element={<DummyPage />} />
          <Route path="/quizCategory/:quizType" element={<DummyPage />} />
          <Route path="/quizCategory/:quizType/:quizSet" element={<DummyPage />} />

          {/* Routes for BlogPage (new) */}
          <Route path="/blogsCategory" element={<BlogPage />} />
          <Route path="/blogsCategory/:blogType" element={<BlogPage />} />
          <Route path="/blogsCategory/:blogType/:blogId" element={<BlogPage />} />
          <Route path="/blogsCategory/:blogType/:blogId/:blogPost" element={<BlogPage />} /> {/* New route for blog posts */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
