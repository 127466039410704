import React, { useContext } from 'react';
import "./HomePage.css";
import { useNavigate } from 'react-router-dom';
import { themeContext } from '../../Context';
import Slider from "react-slick"; // Library for carousel/slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet'; // Import Helmet


// Mock data for slider and cards
const sliderData = [
    { id: 3, title: "Practice Geography PYQs", image: "https://s3-ap-south-1.amazonaws.com/ricedigitals3bucket/AUPortalContent/2021/08/04083303/Untitled-design-81.png", link: "/quizCategory/geography-pyq-sscvidya" },
    { id: 1, title: "Current Affairs 2024", image: "https://blog.cafunsta.com/wp-content/uploads/2022/10/Monthly-Current-Affairs.png", link: "quizCategory/current-affairs-2024" },
    { id: 2, title: "Festivals SSC PYQ", image: "https://www.holidaymonk.com/wp-content/uploads/2022/04/Holi-Festival-of-Colors-1024x577.jpg", link: "/quizCategory/festivals-pyq-sscvidya" },
];

// const cardData = [
//     { id: 1, title: "SSC Topicwise Quizzes", description: "Practice topic-wise quizzes for SSC exams.", link: "/quizCategory" },
//     { id: 2, title: "Featured Blogs", description: "Read insightful articles to boost your prep.", link: "/blogsCategory" },
//     {
//         id: 3, title: "Featured Blogs", description: "Read insightful articles on India's Five-Year Plans.", link: "/blogsCategory/polity/0/FiveYearPlans"
//     },
//     { id: 4, title: "Featured Blogs", description: "Read insightful articles on Prehistoric Periods.", link: "/blogsCategory/history/0/StoneAge" },
//     {
//         id: 5, title: "Featured Blogs", description: "Read insightful articles Constitutional Provisions.", link: "/blogsCategory/polity/0/ConstitutionalParts"
//     },

// ];

const cardData = [
    { id: 1, title: "SSC Topicwise Quizzes", description: "Practice topic-wise quizzes for SSC exams.", link: "/quizCategory", backgroundImage: "https://asts01.mahendras.org/024122021112350_2.jpg" },
    { id: 2, title: "Featured Blogs", description: "Read insightful articles to boost your prep.", link: "/blogsCategory", backgroundImage: "https://via.placeholder.com/300x200" },
    {
        id: 3, title: "Featured Blogs", description: "Read insightful articles on India's Five-Year Plans.", link: "/blogsCategory/polity/0/FiveYearPlans", backgroundImage: "https://via.placeholder.com/300x200"
    },
    { id: 4, title: "Featured Blogs", description: "Read insightful articles on Prehistoric Periods.", link: "/blogsCategory/history/0/StoneAge", backgroundImage: "https://via.placeholder.com/300x200" },
    {
        id: 5, title: "Featured Blogs", description: "Read insightful articles Constitutional Provisions.", link: "/blogsCategory/polity/0/ConstitutionalParts", backgroundImage: "https://via.placeholder.com/300x200"
    },
    {
        id: 6, title: "Parmar Sir Mindmaps", description: "Parmar Classes Mindmaps for Government Exam Subjects.", link: "/blogsCategory/Documents/0/ParmarSirNotes", backgroundImage: "https://via.placeholder.com/300x200"
    },
];

const cardDataSecond = [
    {
        id: 1,
        title: "Topicwise SSC Quizzes",
        description: "Practice topic-wise quizzes for SSC exams.",
        image: "https://study.com/cimages/multimages/16/img_77358528836209341208499.jpg",
        link: "/quizCategory",
    },
    {
        id: 2,
        title: "SSC 2022 PYQ",
        description: "Practice SSC 2022 PYQ to boost your preparation.",
        image: "https://asts01.mahendras.org/024122021112350_2.jpg",
        link: "/quizCategory/ssc-2022-pyq-sscvidya",
    },
    {
        id: 3,
        title: "Gharanas Previous Year Questions",
        description: "Solve past year papers for better understanding.",
        image: "https://i0.wp.com/riyazapp.com/wp-content/uploads/2023/03/raja-750x430.jpg.webp?fit=748%2C429&ssl=1",
        link: "/quizCategory/gharanas-pyq-sscvidya",
    },
    {
        id: 4,
        title: "Mordern History Previous Year Questions",
        description: "Solve past year papers for better understanding.",
        image: "https://gyanchakra.co.in/wp-content/uploads/2024/04/users2Fw1qPs2bgXeNX0A3jw4NJUE5n1Y932FYNFeo03BtVIfxAKqDJmQ2F17faa4b9-16d5-485d-81d4-b493063c4cd8.png",
        link: "/quizCategory/mordern-history-pyq-sscvidya",
    },
    {
        id: 5,
        title: "Polity Previous Year Questions",
        description: "Solve past year papers for better understanding.",
        image: "https://img.freepik.com/free-vector/people-taking-part-political-event-flat-illustration_74855-16914.jpg?t=st=1733544188~exp=1733547788~hmac=38caed3f6c2448b74dc2222119d3a3fe0fe07e791b4b5b09e79f63aa4acdcf0d&w=1800",
        link: "/quizCategory/polity-pyq-sscvidya",
    },
    {
        id: 6,
        title: "Economics Previous Year Questions",
        description: "Solve past year papers for better understanding.",
        image: "https://img.freepik.com/free-vector/man-watching-video-about-financial-literacy-lockdown-male-person-sitting-gold-coins-flat-vector-illustration_74855-18428.jpg?t=st=1733937809~exp=1733941409~hmac=acb342181e1431c2862967f41cd694df9dc686ecc7e41727cf255cb7a502345d&w=1800",
        link: "/quizCategory/economics-pyq-2024-sscvidya",
    },
];

function HomePage() {
    const navigate = useNavigate();

    // Slider settings
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div style={{ padding: '20px', minHeight: '100vh', overflowY: 'auto' }}>
            <Helmet>
                {/* Page Title */}
                <title>SSC Vidya - SSC Exam Preparation | CGL, CHSL, MTS Mock Tests</title>

                {/* Meta Description */}
                <meta
                    name="description"
                    content="Prepare for SSC exams with SSC Vidya. Access topicwise MCQs, mock tests, and quizzes for SSC CGL, CHSL, MTS, and RRB exams. Your SSC success starts here."
                />

                {/* Keywords */}
                <meta
                    name="keywords"
                    content="sscvidya, ssc vidya, ssc mock test, ssc quiz, ssc cgl mcqs, ssc preparation tips, ssc mts, rrb exams, topicwise mcqs, sscvidya.in"
                />

                {/* Open Graph Tags for Social Sharing */}
                <meta property="og:title" content="SSC Exam Preparation | Master Topicwise Quizzes" />
                <meta
                    property="og:description"
                    content="Attempt topicwise quizzes, PYQs, and mock tests for SSC CGL, CHSL, MTS, and RRB exams. Get the edge with SSC Vidya."
                />
                <meta property="og:image" content="/images/ssc-exam-preparation.png" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />

                {/* Canonical URL for SEO */}
                <link rel="canonical" href={window.location.href} />

                {/* Structured Data for SEO */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "SSC Vidya",
                        "url": "https://sscvidya.in",
                        "logo": "https://sscvidya.in/logo.png",
                        "description": "SSC Vidya offers quizzes, topicwise MCQs, and mock tests for SSC CGL, CHSL, MTS, and RRB exams.",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://sscvidya.in/?q={search_term}",
                            "query-input": "required name=search_term"
                        },
                        "sameAs": [
                            "https://facebook.com/sscvidya",
                            "https://twitter.com/sscvidya",
                            "https://instagram.com/sscvidya"
                        ]
                    })}
                </script>
            </Helmet>

            {/* Slider Section */}
            <div style={{ marginBottom: '30px' }}>
                <Slider {...sliderSettings}>
                    {sliderData.map((slide) => (
                        <div key={slide.id} style={{ position: 'relative' }}>
                            <div>
                                <img
                                    onClick={() => navigate(slide.link)}
                                    src={slide.image}
                                    alt={slide.title}
                                    style={{
                                        width: '100%', borderRadius: '10px', maxHeight: '300px', objectFit: 'cover', cursor: 'pointer',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginTop: "10px",
                                    bottom: '5px',
                                    width: "80%",
                                    // left: '20px',
                                    color: '#fff',
                                    background: 'rgba(0, 0, 0, 0.5)',
                                    padding: '5px 0px 5px 0px',
                                    // borderRadius: '2%',
                                    // marginLeft: "-20px"
                                }}
                            >
                                <h3 style={{ marginLeft: "4vw", fontWeight: "bold" }}>{slide.title}</h3>
                                {/* <button
                                    onClick={() => navigate(slide.link)}
                                    style={{
                                        background: '#007bff',
                                        border: 'none',
                                        color: '#fff',
                                        padding: '5px 10px',
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                    }}
                                >
                                    Explore
                                </button> */}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Card Section */}
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>Recently Added  </span>
                    <span>Questions Sets </span>
                    <span>Topic-Wise Questions for </span>
                    <span>CGL, MTS, CHSL</span>
                </div>
                {[...cardDataSecond].reverse().map((card) => (
                    <div
                        key={card.id}
                        onClick={() => navigate(card.link)}
                        style={{
                            cursor: 'pointer',
                            border: '2px solid #ccc',
                            borderRadius: '10px',
                            width: '250px',
                            overflow: 'hidden',
                            background: darkMode ? "black" : "white",
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                        }}
                    >
                        <img
                            src={card.image}
                            alt={card.title}
                            style={{
                                width: '100%',
                                height: '200px',
                                objectFit: 'cover',
                                borderBottom: '1px solid #ccc',
                            }}
                        />
                        <div style={{ padding: '15px' }}>
                            <h3 style={{ margin: '10px 0', fontSize: '18px', color: darkMode ? "white" : '#333' }}>{card.title}</h3>
                            <p style={{ color: darkMode ? "white" : "#666", fontSize: '14px', marginBottom: '20px' }}>
                                {card.description}
                            </p>
                            {/* <button
                                style={{
                                    background: '#007bff',
                                    border: 'none',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                }}
                            >
                                Learn More
                            </button> */}
                        </div>
                    </div>
                ))}
            </div>

            <div className="gettingStart_container">
                <div className="details_container">
                    <div className="get_Started_text1">EXPLORE THE NEW WAY Of LEARNING</div>
                    <div className="get_Started_text2" >Ready to unlock your potential? Practice SSC PYQ questions online.</div>
                    <button style={{ color: "white" }} className="start_button" onClick={() => navigate("/quizCategory")}>GET STARTED</button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img className="image_style" src={"https://img.freepik.com/premium-vector/woman-sitting-desk-focused-studying-with-laptop-open-stack-books-beside-her-studying-woman-graduate-succes-trending-simple-minimalist-flat-vector-illustration_538213-70175.jpg?semt=ais_hybrid"} alt="dimension"></img>
                </div>
            </div>

            {/* Larger Cards Section */}
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center', marginTop: "30px" }}>
                <div className="t-heading" style={{ marginBottom: "20px" }}>
                    <span>SSCVIDYA Most Popular </span>
                    <span>Topic-Wise Questions Sets </span>
                    <span>CGL, MTS & CHSL </span>
                </div>
                {cardData.map((card) => (
                    <div
                        key={card.id}
                        onClick={() => navigate(card.link)}
                        style={{
                            cursor: 'pointer',
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            width: '300px',
                            textAlign: 'center',
                            padding: '20px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            position: 'relative',
                            background: darkMode ? "black" : "rgb(243 243 243)",
                            marginBottom: "15px"
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                        }}
                    >
                        {/* Blurred Background Image */}
                        {/* <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${card.backgroundImage})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                // filter: 'blur(1px)',
                                zIndex: 0, // Ensures the background stays behind the content
                                opacity: 0.2, // Subtle effect for readability
                                borderRadius: '10px',

                            }}

                        ></div> */}
                        <h3 style={{ margin: '10px 0', fontWeight: "bolder", color: darkMode ? "white" : "black" }}>{card.title}</h3>
                        <p style={{ color: darkMode ? "white" : "#666" }}>{card.description}</p>
                        {/* <button
                            style={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                background: '#007bff',
                                border: 'none',
                                color: '#fff',
                                padding: '10px 20px',
                                cursor: 'pointer',
                                borderRadius: '5px',
                            }}
                        >
                            Learn More
                        </button> */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HomePage;
