//src/firebase/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Import auth
import { getFirestore } from "firebase/firestore"; // Import Firestore

const firebaseConfig = {
    apiKey: "AIzaSyDAmcrhTfLrIKj8Yz3My1wuhRpJ_-pwfsY",
    authDomain: "quizup-project.firebaseapp.com",
    // authDomain: "itsaurav.netlify.app",
    projectId: "quizup-project",
    storageBucket: "quizup-project.firebasestorage.app",
    messagingSenderId: "735945288999",
    appId: "1:735945288999:web:f7198c30bbb8d0254fcd02",
    measurementId: "G-C9Y2WDQHR1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app); // Add this line

// const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Firebase Auth
const googleProvider = new GoogleAuthProvider(); // Set up Google Auth provider
const db = getFirestore(app); // Initialize Firestore

export { auth, googleProvider, db, analytics }; // Export auth This is how currently i have integrated the db