// src/firebase/firestoreFunctions.js
import { doc, setDoc, collection, getDocs, query, orderBy, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

// Function to save a user in Firestore
// export const saveUserToFirestore = async (user) => {
//     try {
//         await setDoc(doc(db, "users", user.uid), {
//             name: user.displayName,
//             photoURL: user.photoURL,
//             email: user.email,
//             createdAt: new Date(),
//             role: "user",
//             phoneNumber: user.phoneNumber || "",
//             lastLoginAt: new Date(),

//         });
//     } catch (error) {
//         console.error("Error adding user to Firestore:", error);
//     }
// };
export const saveUserToFirestore = async (user) => {
    try {
        const userDocRef = doc(db, "users", user.uid);
        // Attempt to get the user document
        const userDoc = await getDoc(userDocRef);
        // console.log("Document exists:", userDoc.exists());
        // console.log("Document exists 2:", user.uid);

        // If user doesn't exist, create a new record and set createdAt
        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                name: user.displayName,
                photoURL: user.photoURL,
                email: user.email,
                createdAt: new Date(),  // Set createdAt only once
                role: "user",
                phoneNumber: user.phoneNumber || "",
                lastLoginAt: new Date(), // Set lastLoginAt
            });
        } else {
            // If user exists, just update the lastLoginAt field
            await setDoc(userDocRef, {
                lastLoginAt: new Date(),
            }, { merge: true });
        }
    } catch (error) {
        console.error("Error adding user to Firestore:", error);
    }
};

// Function to retrieve all users from Firestore
export const fetchAllUsers = async () => {
    try {
        const usersCollection = collection(db, "users");
        const userSnapshot = await getDocs(usersCollection);
        return userSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error("Error fetching users from Firestore:", error);
        return [];
    }
};

// Fetch Users from firestore
export const fetchUsers = async () => {
    try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, orderBy('name'));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
    } catch (error) {
        console.error("Error fetching users:", error);
        return []; // Return an empty array if there's an error
    }
};

