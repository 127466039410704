// src/firebase/AuthListener.js
import { auth } from "./firebaseConfig";
import { saveUserToFirestore } from "./firestoreFunctions";

auth.onAuthStateChanged((user) => {
    if (user && user.emailVerified) {
        // Only add user if they're not already in Firestore (optional: add a check)
        saveUserToFirestore(user);
    }
});
