import React, { useContext, useState } from "react";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
import { themeContext } from "../Context";

const SharePopup = ({ question, onClose }) => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    // Prevent scrolling on the background
    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    //correct/incorrect answer highlighting feature

    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionClick = (questionId, option) => {
        setSelectedOption(option);
    };

    const getOptionStyle = (selectedOption, correctAnswer, option) => {
        if (option === correctAnswer && selectedOption === option) {
            return { backgroundColor: 'lightgreen', color: 'black' }; // Correctly selected
        }
        if (option === selectedOption && option !== correctAnswer) {
            return { backgroundColor: 'lightcoral', color: 'black' }; // Incorrectly selected
        }
        return {}; // Default style
    };

    // const handleDownload = () => {
    //     const element = document.getElementById(`share-question-${question.id}`);
    //     toPng(element, {
    //         quality: 1,
    //         canvasWidth: 644,
    //         canvasHeight: 342,
    //     })
    //         .then((dataUrl) => {
    //             saveAs(dataUrl, `question-${question.id}.png`);
    //         })
    //         .catch((err) => console.error("Error generating image:", err));
    // };

    const handleDownload = () => {
        const element = document.getElementById(`share-question-${question.id}`);
        if (!element) {
            console.error("Element not found");
            return;
        }

        const { width, height } = element.getBoundingClientRect(); // Get element's current dimensions
        const aspectRatio = width / height;

        // Adjust canvas size proportionally
        const canvasWidth = 300; // Example base width
        const canvasHeight = canvasWidth / aspectRatio;

        toPng(element, {
            quality: 1,
            canvasWidth,
            canvasHeight,
        })
            .then((dataUrl) => {
                saveAs(dataUrl, `question-${question.id}.png`);
            })
            .catch((err) => console.error("Error generating image:", err));
    };

    // Handle copy to clipboard functionality
    const handleCopyToClipboard = async () => {
        if (!navigator.clipboard || !window.ClipboardItem) {
            console.error("Clipboard API not supported in this browser.");
            alert("Clipboard API is not supported.");
            return;
        }

        const element = document.getElementById(`share-question-${question.id}`);
        if (!element) return;

        const { width, height } = element.getBoundingClientRect(); // Get element's current dimensions
        const aspectRatio = width / height;

        // Adjust canvas size proportionally
        const canvasWidth = 300; // Example base width
        const canvasHeight = canvasWidth / aspectRatio;

        try {
            const dataUrl = await toPng(element, { quality: 1, canvasWidth, canvasHeight });
            const blob = await fetch(dataUrl).then(res => res.blob());

            const item = new window.ClipboardItem({ 'image/png': blob });
            await navigator.clipboard.write([item]);

            alert('Image copied to clipboard!');
        } catch (err) {
            console.error('Error copying to clipboard:', err);
        }
    };


    return (
        <>
            {/* Overlay for background blur and disabling interaction */}
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                    backdropFilter: "blur(5px)", // Blur effect
                    zIndex: 999, // Ensure it's below the popup
                }}
            ></div>
            {/* SharePopup Modal*/}
            <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    background: darkMode ? "#222" : "white",
                    color: darkMode ? "white" : "black",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    maxWidth: "90%",
                    width: "600px",
                    maxHeight: "90%", // Constrain modal height
                    overflowY: "auto", // Enable vertical scrolling
                }}
            >
                <h3>Share or Download</h3>
                <div
                    id={`share-question-${question.id}`}
                    style={{
                        background: darkMode ? "#333" : "white",
                        color: darkMode ? "white" : "black",
                        border: darkMode ? "2px solid #555" : "2px solid #000",
                        borderRadius: "10px",
                        padding: "20px",
                        margin: "0 auto",
                        textAlign: "center",
                    }}
                >
                    <div style={{
                        marginBottom: "20px", border: darkMode ? "1px solid #555" : "1px solid #000", borderRadius: "10px", padding: "20px"
                    }}>
                        <h2 style={{ fontSize: "20px", marginBottom: "10px" }}>
                            {question.question}
                            <span style={{ fontWeight: "bold" }}> {question?.exam}</span>
                        </h2>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                            {question.options.map((option, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleOptionClick(question.id, option)}
                                    style={{
                                        padding: "10px",
                                        margin: "5px 0",
                                        cursor: "pointer",
                                        border: darkMode ? "1px solid #666" : "1px solid #ddd",
                                        borderRadius: "5px",
                                        ...getOptionStyle(selectedOption, question.correctAnswer, option),
                                    }}
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <footer
                        style={{
                            position: "relative",
                            bottom: "10px",
                            background: darkMode ? "#555" : "#f1f1f1",
                            color: darkMode ? "white" : "black",
                            padding: "5px 10px",
                            borderRadius: "5px",
                        }}
                    >
                        <p style={{ fontSize: "14px", margin: 0 }}>© 2024 SSCVIDYA.IN</p>
                    </footer>
                    {/* Watermark */}
                    {/* <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "18px",
                            color: "rgba(0, 0, 0, 0.1)", // Faded color
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                            pointerEvents: "none",
                            userSelect: "none",
                        }}
                    >
                        SSCVIDYA.IN
                    </div> */}
                </div>
                <button
                    onClick={handleDownload}
                    className="button"
                    style={{ marginTop: "10px" }}
                >
                    Download
                </button>

                <button
                    onClick={handleCopyToClipboard}
                    className="button"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                >
                    Copy Clipboard
                </button>

                <button
                    onClick={onClose}
                    className="button"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                >
                    Close
                </button>
            </div>
        </>

    );
};

export default SharePopup;
