import React from 'react';
import "./Intro.css";
import Github from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png"
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
import boy from "../../img/boy.png";
import thumbup from "../../img/thumbup.png";
import Crown from '../../img/crown.png';
import glassesimoji from "../../img/glassesimoji.png";
import FloatingDiv from '../floatingDiv/FloatingDiv';
import { Helmet } from 'react-helmet';
//THEME
import { themeContext } from "../../Context";
import { useContext } from "react";

//MOTION
import { motion } from 'framer-motion';

//SCROLL
import { Link } from 'react-scroll';


function Intro() {

  const transition = { duration: 2, type: 'spring' };

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="intro">
      <div className="i-left">
        <Helmet>
          <title>Saurav - Front-end Engineer</title>
          <meta name="description" content="Hi! I am Saurav, a front-end engineer skilled in HTML, JavaScript, React, CSS, SCSS, and Next.js. I design and develop user-friendly web applications." />
          <meta name="keywords" content="Saurav Front-end Engineer, HTML, JavaScript, React, CSS, SCSS, Next.js, Saurav Web Development, User Experience, Saurav Frontend Engineer" />
          <meta name="author" content="Saurav" />
        </Helmet>
        <div className="i-name">
          <span style={{ color: darkMode ? 'white' : '' }}>Hi! I Am </span>
          <span>Saurav </span>
          <span>Seeking a challenging front-end engineering role where I can leverage my expertise in HTML, JavaScript, React, CSS, SCSS, and Next.js to design and develop innovative web applications. I am passionate about creating user-friendly and visually appealing websites, and I am committed to staying up-to-date with the latest technologies and trends in the field. My goal is to contribute to the success of a dynamic and innovative organisation by delivering high-quality, user-centered web solutions.
          </span>
        </div>

        <Link to="contact" spy={true} smooth={true}>
          <button className="button i-button">Hire Me</button>
        </Link>

        <div className="i-icons">
          <a href='https://github.com/SauravHi'>
            <img src={Github} alt="Github Pic" />
          </a>
          <a href='https://www.linkedin.com/in/suman-saurav00 '>
            <img src={LinkedIn} alt="LinkedIn Pic" />
          </a>
          <a href='https://www.instagram.com/itsaurav5/'>
            <img src={Instagram} alt="Instagram Pic" />
          </a>

        </div>
      </div>

      <div className="i-right">
        <img src={Vector1} alt="" />
        <img src={Vector2} alt="" />
        <img src={boy} alt="" />

        <motion.img
          initial={{ left: '-36%' }}
          whileInView={{ left: '-24%' }}
          transition={transition}
          src={glassesimoji} alt="" />

        <motion.div
          initial={{ top: '-4%', left: '74%' }}
          whileInView={{ left: '68%' }}
          transition={transition}
          style={{ top: '-4%', left: '60%' }}
          className="floating-div">

          <FloatingDiv image={Crown} txt1="Web" txt2="Developer" />
        </motion.div>

        <motion.div
          initial={{ left: '12rem', top: '18rem' }}
          whileInView={{ left: '8rem' }}
          transition={transition}
          style={{ top: '18rem', left: '2.3rem' }}
          className="floating-div">
          <FloatingDiv image={thumbup} txt1="Best" txt2="Design" />
        </motion.div>

        {/* BLUR DIVS */}

        <div className="blur" style={{ background: "rgb (238 210 255)" }}></div>

        <div className="blur" style={{
          background: '#C1F5FF',
          top: '17rem',
          width: '21rem',
          height: '11rem',
          left: '-9rem'
        }}></div>

      </div>
    </div>
  )
}

export default Intro