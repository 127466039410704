import React from 'react'
import "./Portfolio.css";

//SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from "swiper";
import 'swiper/css';


//IMAGES
import SwiperList from "../../img/SwiperList.png";
import compass from "../../img/compass.png";
import arcadeHouse from "../../img/arcadeHouse.png";
import Assidus from "../../img/Assidus.png";

//THEME
import { themeContext } from "../../Context";
import { useContext } from "react";

const Portfolio = () => {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="portfolio" id='Portfolio'>
      {/* HEADING */}
      <span style={{ color: darkMode ? 'white' : "" }}>Recent Projects</span>
      <span>Portfolio</span>

      {/* SLIDER */}
      <Swiper
        className="portfolio-slider"
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        breakpoints={{
          480: {
            width: 480,
            slidesPerView: 1,
          },

          1000: {
            width: 1000,
            spaceBetween: 30,
            slidesPerView: 2,
            grabCursor: true,
          },
        }}

        loop={true}
        speed={1000}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false
        }}

        zoom={{
          maxRatio: 1.2,
          minRatio: 1
        }}




      // spaceBetween={30}
      // slidesPerView={3}
      // grabCursor={true}
      >
        <SwiperSlide >
          <a href='https://nxtwave-list.netlify.app' target="_blank" rel="noopener noreferrer">
            <img src={SwiperList} alt="Something" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href='https://watchlistflow.netlify.app' target="_blank" rel="noopener noreferrer">
            <img src={Assidus} alt="Something" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href='https://compass.nextdoorkitchens.in' target="_blank" rel="noopener noreferrer">
            <img src={compass} alt="Something" />
          </a>
        </SwiperSlide>

        <SwiperSlide>
          <a href='https://arcadehouse.netlify.app' target="_blank" rel="noopener noreferrer">
            <img src={arcadeHouse} alt="Something" />
          </a>
        </SwiperSlide>
      </Swiper>
    </div >
  )
}

export default Portfolio