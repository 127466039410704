import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination, Autoplay } from "swiper";
import "swiper/css/pagination";
// import profilePic1 from "../../img/profile1.jpg";
// import profilePic2 from "../../img/profile2.jpg";
// import profilePic3 from "../../img/profile3.jpg";
// import profilePic4 from "../../img/profile4.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: "https://media.licdn.com/dms/image/v2/C5103AQFjdyS_Xs-mOA/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1573150267793?e=1735171200&v=beta&t=Oq0qlkBcPUOp0lfE1GJ3x5-4ecDKVnEBZNMFqm08oTw",
      review:
        "Saurav’s work on the POS Website project was transformative. His expertise in React.js and JavaScript helped create an efficient, user-friendly system. He’s a collaborative team player, always ready to support and share knowledge.",
    },
    {
      img: "https://media.licdn.com/dms/image/v2/D5603AQE3jUlXZ-l6xg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1687782371074?e=1735171200&v=beta&t=jRhntqYJS79IKUCOakr1nbUi77C7YWhma-12SmrzEyw",
      review:
        "Saurav was a standout in our IT batch at BIT Ranchi, known for his curiosity and hands- on learning.His technical insight and problem - solving skills made him a go - to resource among classmates.",
    },
    {
      img: "https://media.licdn.com/dms/image/v2/C5103AQEIuUzTKFsV6Q/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1569098558577?e=1735171200&v=beta&t=K4RnLWZDurd5PUp1ShRapByc8QNOTcJhPk5YdxA9FQk",
      review:
        "Saurav transformed Figma designs into beautiful, functional pages for our E-Commerce Website, showcasing his mastery in Next.js and frontend development. His attention to user experience made him an invaluable asset to our team.",
    },
    {
      img: "https://media.licdn.com/dms/image/v2/C5103AQGXHXTlWgXwxw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1562610291188?e=1735171200&v=beta&t=JEtYAu9y32R8xCbZbNmvxWYFW0kZutmrcLkKP6sb0VA",
      review:
        "Saurav’s problem-solving skills and frontend expertise in Next.js made him a fantastic teammate. His enthusiasm for learning and openness to feedback made every project smooth and enjoyable to work on together.",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span>
        <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
        <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        grabCursor={true}
        speed={1000}//For smooth Transition.
        autoplay={{
          delay: 1000,
          disableOnInteraction: false
        }}
        loop={true}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;