// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
// import { auth } from '../../firebase/firebaseConfig';
// import './AuthPage.css';

// function SignUpPage() {
//     const [displayName, setDisplayName] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState('');
//     const navigate = useNavigate();

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!displayName || !email || !password || !confirmPassword) {
//             setError('Please fill in all fields');
//         } else if (password !== confirmPassword) {
//             setError('Passwords do not match');
//         } else {
//             try {
//                 // Create user with Firebase Auth
//                 const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//                 const user = userCredential.user;

//                 // Set the displayName for the newly created user
//                 await updateProfile(user, {
//                     displayName: displayName,
//                 });

//                 navigate('/quizCategory'); // Redirect to dashboard on success
//             } catch (error) {
//                 setError(error.message); // Display error if signup fails
//                 alert(error.message);
//             }
//         }
//     };

//     return (
//         <div className="auth-container">
//             <div className="auth-box">
//                 <h2>Sign Up</h2>
//                 <form onSubmit={handleSubmit} className="auth-form">
//                     <input
//                         type="text"
//                         placeholder="Username"
//                         value={displayName}
//                         onChange={(e) => setDisplayName(e.target.value)}
//                         className="auth-input"
//                     />
//                     <input
//                         type="email"
//                         placeholder="Email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         className="auth-input"
//                     />
//                     <input
//                         type="password"
//                         placeholder="Password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         className="auth-input"
//                     />
//                     <input
//                         type="password"
//                         placeholder="Confirm Password"
//                         value={confirmPassword}
//                         onChange={(e) => setConfirmPassword(e.target.value)}
//                         className="auth-input"
//                     />
//                     {error && <div className="error-message">{error}</div>}
//                     <button type="submit" className="button n-button">Sign Up</button>
//                 </form>
//                 <p className="auth-toggle">
//                     Already have an account? <span onClick={() => navigate('/login')}>Login</span>
//                 </p>
//             </div>
//         </div>
//     );
// }

// export default SignUpPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../firebase/firebaseConfig';
import './AuthPage.css';

function SignUpPage() {
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!displayName || !email || !password || !confirmPassword) {
            setError('Please fill in all fields');
        } else if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            try {
                // Create user with Firebase Auth
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Set the displayName for the newly created user
                await updateProfile(user, {
                    displayName: displayName,
                });

                // Send email verification
                await sendEmailVerification(user);

                // Set message to notify user about verification email
                setMessage("Verification email sent. Please check your inbox and verify your email before logging in.");

                // Log out the user after sending verification email
                await auth.signOut(); // Log out the user to prevent them from accessing any other routes without verifying

                // Redirect to login page after a short delay
                setTimeout(() => navigate('/login'), 5000); // Redirect to login page after 5 seconds
            } catch (error) {
                setError(error.message); // Display error if signup fails
                alert(error.message);
            }
        }
    };


    return (
        <div className="auth-container">
            <div className="auth-box">
                <h2>Sign Up</h2>
                <form onSubmit={handleSubmit} className="auth-form">
                    <input
                        type="text"
                        placeholder="Username"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        className="auth-input"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="auth-input"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="auth-input"
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="auth-input"
                    />
                    {error && <div className="error-message">{error}</div>}
                    {message && <div className="success-message">{message}</div>}
                    <button type="submit" className="button n-button">Sign Up</button>
                </form>
                <p className="auth-toggle">
                    Already have an account? <span onClick={() => navigate('/login')}>Login</span>
                </p>
            </div>
        </div>
    );
}

export default SignUpPage;
