import React from 'react';
import "./Services.css";

import Card from '../card/Card';
import HeartEmoji from "../../img/heartemoji.png"
import Glasses from "../../img/glasses.png"
import Humble from "../../img/humble.png"

//FILE
import Resume from "./Resume.pdf";

//MOTION
import { motion } from 'framer-motion';

//THEME
import { themeContext } from "../../Context";
import { useContext } from "react";

const Services = () => {

  const transition = { duration: 2, type: 'spring' };

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;




  return (
    <div className="services" id='Services'>

      {/* Left Side */}
      <div className="awesome">
        <span style={{ color: darkMode ? 'white' : '' }}>My Awesome</span>
        <span>Services</span>
        <span>
          <p>HTML, CSS, JavaScript, React & Web Development.</p>

        </span>
        <a href={Resume} download>
          <button className="button s-button">
            Download CV
          </button>
        </a>

        {/* BLUR */}
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>

      </div>

      {/* Right Side */}

      <div className="cards">
        {/* FIRST CARD */}
        <motion.div
          style={{ top: '2rem', left: "28rem" }}
          initial={{ top: '2rem', left: '25rem' }}
          whileInView={{ left: '23rem' }}
          transition={transition}
        >

          <Card
            className="card"
            emoji={HeartEmoji}
            heading={'Design'}
            detail={"Figma, Sketch, Photoshop, Adobe XD"}
          />
        </motion.div>

        {/* SECOND CARD */}
        <motion.div
          style={{ top: "12rem", left: "2rem" }}
          initial={{ top: '12rem', left: '8rem' }}
          whileInView={{ left: '8rem' }}
          transition={transition}
        >
          <Card
            className="card"
            emoji={Glasses}
            heading={'Developer'}
            detail={"HTML, CSS, JavaScript, React"}
          />
        </motion.div>

        {/* THIRD CARD */}
        <motion.div
          style={{ top: "25rem", left: "28rem" }}
          initial={{ top: '19.5rem', left: '25rem' }}
          whileInView={{ left: '23rem' }}
          transition={transition}
        >
          <Card

            className="card"
            emoji={Humble}
            heading={'UI/UX'}
            detail={"SOMETHING GOES HERE"}
          />
        </motion.div>

        <div className="blur s-blur2" style={{ background: "var(--purple)" }}></div>

      </div>

    </div>
  )
}

export default Services