import { useState, useEffect } from "react";
import { auth } from "../firebase/firebaseConfig";

const useLoginPromptAuth = () => {
    const [user, setUser] = useState(null);
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setIsCheckingAuth(false);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {

        if (!isCheckingAuth && !user) {
            const timer = setTimeout(() => {
                setIsModalOpen(true);
            }, 15000); // 15 seconds
            return () => clearTimeout(timer);
        }
    }, [isCheckingAuth, user]);

    const closeModal = () => setIsModalOpen(false);

    return { user, isCheckingAuth, isModalOpen, closeModal };
};

export default useLoginPromptAuth;
