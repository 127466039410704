// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../firebase/firebaseConfig';
import { saveUserToFirestore } from '../../firebase/firestoreFunctions';
import './AuthPage.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Standard email/password login
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!email || !password) {
    //         setError('Please fill in all fields');
    //         return;
    //     } else {
    //         try {
    //             await signInWithEmailAndPassword(auth, email, password);
    //             window.alert("Signed in successfully!"); // Alert on successful sign-in
    //             navigate('/quizCategory');
    //         } catch (error) {
    //             setError(error.message || 'Invalid email or password'); // Provide more detailed error
    //         }
    //     }
    // };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setError('Please fill in all fields');
            return;
        }

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Check if the user's email is verified
            if (!user.emailVerified) {
                setError("Please verify your email before logging in.");
                await auth.signOut(); // Sign out if not verified
                return;
            }

            // Save verified user to Firestore
            await saveUserToFirestore(user);
            window.alert("Signed in successfully!");
            navigate('/'); // Redirect after successful login

        } catch (error) {
            setError(error.message || 'Invalid email or password');
        }
    };


    // Google login
    const handleGoogleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            window.alert("Signed in successfully!"); // Alert on successful sign-in
            navigate('/'); // Redirect on successful login
        } catch (error) {
            setError(error.message || 'Google sign-in failed'); // Provide specific error message
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-box">
                <h2>Login</h2>
                <form onSubmit={handleSubmit} className="auth-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="auth-input"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="auth-input"
                    />
                    {error && <div className="error-message">{error}</div>}
                    <button type="submit" className="button n-button">Login</button>
                </form>

                <button onClick={handleGoogleSignIn} className="button n-button" style={{ marginTop: "15px" }}>
                    Sign in with Google
                </button>

                <p className="auth-toggle">
                    Don't have an account? <span onClick={() => navigate('/signup')}>Sign Up</span>
                </p>
            </div>
        </div>
    );
}

export default LoginPage;
