import quizzes from "./quizzes.json";
import sscmts2024 from "./sscmts2024.json"
import polity from "./polity.json"
import economics from "./economics.json";
import history from "./history.json";

const allData = [
    ...quizzes.categories, sscmts2024, polity, economics, history
]

export default allData;
