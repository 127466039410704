import React, { useState, useContext } from "react";
import './AuthPage.css';
import Modal from "react-modal";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebase/firebaseConfig";
import { saveUserToFirestore } from "../../firebase/firestoreFunctions";
import { themeContext } from "../../Context";
import { useNavigate } from 'react-router-dom';

const LoginPromptModal = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const navigate = useNavigate();

    function afterOpenModal() {
        document.body.style.overflow = 'hidden'; // Prevent background scroll
    }

    function afterCloseModal() {
        document.body.style.overflow = 'auto'; // Prevent background scroll
    }

    const modalStyle = {
        content: {
            // width: "60vw",
            height: "80%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: darkMode ? "#2b2929" : "white",
            color: darkMode ? "white" : "",
            border: "none",
            overflowX: 'hidden'
        },
        overlay: {
            backgroundColor: "rgba(12, 12, 12, 0.5)",
        },
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Please fill in all fields");
            return;
        }

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                setError("Please verify your email before logging in.");
                await auth.signOut();
                return;
            }

            await saveUserToFirestore(user);
            window.alert("Signed in successfully!");
            onClose(); // Close the modal after login
        } catch (error) {
            setError(error.message || "Invalid email or password");
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            window.alert("Signed in successfully!");
            onClose(); // Close the modal after login
        } catch (error) {
            setError(error.message || "Google sign-in failed");
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Login Prompt"
            style={modalStyle}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
        >
            <div className="auth-container" style={{ minHeight: "60vh" }}>
                <div className="t-heading" style={{ marginBottom: "15px" }}>
                    <span style={{ fontSize: "1.1rem" }}>Thank you for visiting our page!  </span>
                    <span style={{ fontSize: "1.1rem" }}>Please, join our small community, and explore more amazing content.</span>
                </div>

                <div className="auth-box">
                    <h2 style={{ marginBottom: "20px" }}>Log In</h2>
                    <form onSubmit={handleLoginSubmit} className="auth-form">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="auth-input"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="auth-input"
                        />
                        {error && <div className="error-message">{error}</div>}
                        <button
                            type="submit"
                            style={{ marginTop: "15px" }}
                            className="button n-button"
                        >
                            Log In
                        </button>
                        <button
                            onClick={handleGoogleSignIn}
                            style={{ marginTop: "15px" }}
                            className="button n-button"
                        >
                            Sign in with Google
                        </button>
                    </form>

                    <button
                        onClick={onClose}
                        style={{ marginTop: "15px" }}
                        className="button n-button"
                    >
                        Close
                    </button>
                    <p className="auth-toggle">
                        Don't have an account? <span onClick={() => { navigate('/signup'); onClose() }}>Sign Up</span>
                    </p>
                </div>
            </div>
        </Modal>
    );
};

export default LoginPromptModal;
